import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'
import Loading from '../../components/loading/Loading'
import DataTable from 'react-data-table-component'
import StudentsCountHeader from '../../components/dashboard/StudentsCountHeader'
import StudentsCountContent from '../../components/dashboard/StudentsCountContent'
import LevelSplit from '../../components/offices/LevelSplit'
import RoleOffice from '../../components/offices/RoleOffice'
import { saveAs } from 'file-saver'

function StudentsCount() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(true)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('')

    const [studentsCount, setStudentsCount] = useState({})

    const forms_ordinary = ["Form I", "Form II", "Form III", "Form IV"]
    const forms_primary = ["DRS AWALI", "Pre-Unit II", "Class I", "Class II", "Class III", "Class IV", "Class V", "Class VI", "Class VII"]

    let columns = [
        {
            name: 'S/N',
            selector: (row, index) => index + 1,
            width: "fit-content",
            style: {
                minWidth: "50px"
            }
        },
        {
            name: 'School',
            selector: row => row.school?.name || "Total",
            sortable: true,
            grow: 5,
        }
    ]

    let columns_ordinary = Array.from(columns).concat([...forms_ordinary, "total"].map(form => {
        return {
            name: <StudentsCountHeader name={form} />,
            cell: row => <StudentsCountContent f={row[form]?.female} m={row[form]?.male} t={row[form]?.total} />,
            grow: 2,
            wrap: false
        }
    }))

    let columns_primary = Array.from(columns).concat([...forms_primary, "total"].map(form => {
        return {
            name: <StudentsCountHeader name={form} />,
            cell: row => <StudentsCountContent f={row[form]?.female} m={row[form]?.male} t={row[form]?.total} />,
            grow: 2,
            wrap: false
        }
    }))

    const fetchStudentCount = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const resp = await fetch(`${BASE_API_URL}/count/students`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await resp.json()
            if (!resp.ok) {
                if (resp.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('danger')
                }
            }
            else {
                setStudentsCount(data.count)
            }
        }
        catch (error) {
            setMessage('Something went wrong. Please, try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleExport = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/count/students?export=1`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            if (!response.ok) {
                const data = await response.json()
                setMessage(data.message)
                setVariant("warning")
                return
            }

            let suff = "MSSIS"
            if (auth.user.school) {
                suff = auth.user.school.name
            }

            const filename = `${suff} Students' Statistics ${new Date().toISOString().split('T')[0]}.pdf`
            const blob = await response.blob()
            saveAs(blob, filename)
        }
        catch (error) {
            setMessage("Something went wrong. Please, try again.")
            setVariant("danger")
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchStudentCount()
    }, [])

    return (
        <div className='pt-2'>
            <div className="d-flex flex-wrap align-items-center justify-content-between">
                <p className='h3 fw-bold text-muted'>
                    <LevelSplit primary={"Pupils"} ordinary={"Students"} /> Count
                </p>
                <RoleOffice roles={["Admin"]} reverse={true} element={
                    <button
                        className="btn btn-sm btn-primary rounded-0"
                        disabled={isLoading}
                        onClick={handleExport}
                    >
                        <span className="me-2">Export to PDF</span>
                        <i className="bi bi-file-earmark-pdf"></i>
                    </button>
                } />
            </div>

            {
                message &&
                <div className={`alert alert-${variant} p-2`} role="alert">
                    {message}
                </div>
            }

            <div className="my-3">
                {
                    isLoading ?
                        <div className="text-center">
                            Loading... <Loading />
                        </div>
                        :
                        <>
                            <RoleOffice roles={["Admin"]} reverse={true} element={
                                <DataTable
                                    columns={auth.user.level === "PRIMARY" ? columns_primary : columns_ordinary}
                                    data={studentsCount[auth.user.level]}
                                    highlightOnHover
                                    responsive
                                />
                            } />
                            <RoleOffice roles={["Admin"]} element={
                                <>
                                    <div className="mb-4">
                                        <DataTable
                                            columns={columns_primary}
                                            data={studentsCount["PRIMARY"]}
                                            highlightOnHover
                                            responsive
                                        />
                                    </div>
                                    <div>
                                        <p className='h3 fw-bold text-muted'>
                                            Students Count
                                        </p>
                                        <DataTable
                                            columns={columns_ordinary}
                                            data={studentsCount["ORDINARY"]}
                                            highlightOnHover
                                            responsive
                                        />
                                    </div>
                                </>
                            } />
                        </>
                }
            </div>
        </div>
    )
}

export default StudentsCount
