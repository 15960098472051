import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'
import { Link } from 'react-router-dom'
import FacilitiesTable from '../../components/facilities/FacilitiesTable'
import RoleOffice from '../../components/offices/RoleOffice'

function Facilities() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState("")
    const [variant, setVariant] = useState("success")

    const [schools, setSchools] = useState([])
    const [selectedSchool, setSelectedSchool] = useState("")

    const fetchSchools = async () => {
        setIsLoading(true)
        
        try {
            const response = await fetch(`${BASE_API_URL}/schools`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant("danger")
            }

            setSchools(data.schools)
        }
        catch (error) {
            setMessage("Something went wrong. Please, try again.")
            setVariant("danger")
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchSchools()
    }, [])

    return (
        <div className='pt-2'>
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
                <div>
                    <p className='h3 fw-bold text-muted'>School Facilities</p>
                    <span>A cummulative view of all facilities in all schools.</span>
                </div>
                <RoleOffice roles={["AEK"]} reverse={true} element={
                    <Link
                        to={'manage'}
                        className="btn btn-sm btn-primary rounded-0 fw-bold"
                    >
                        <span className="me-2">Manage</span>
                        <i className="bi bi-plus-square"></i>
                    </Link>
                } />
            </div>

            <div className="my-3 shadow p-2">
                <div className="mb-3">
                    <select 
                        name="school" 
                        id="school" 
                        className="form-select rounded-0"
                        disabled={isLoading}
                        value={selectedSchool}
                        onChange={e => setSelectedSchool(e.target.value)}
                    >
                        <option value="">All Schools</option>
                        {
                            schools?.map((school, index) => (
                                <option value={school.id}>{school.name}</option>
                            ))
                        }
                    </select>
                </div>
                <FacilitiesTable school={selectedSchool} />
            </div>
        </div>
    )
}

export default Facilities
