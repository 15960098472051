import React, { useEffect, useState } from 'react'
import { TabList, Tabs, Tab, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import RoleOffice from '../../components/offices/RoleOffice'
import GeneralSettings from './tabs/GeneralSettings'
import SMSSettings from './tabs/SMSSettings'

function Settings() {
	const [selectedTab, setSelectedTab] = useState(0)

	const handleTabChange = (index) => {
		setSelectedTab(index)
		sessionStorage.setItem("settingsTab", index)
	}

	useEffect(() => {
		const savedTab = sessionStorage.getItem("settingsTab")
		if (savedTab !== null) {
			setSelectedTab(parseInt(savedTab, 10))
		}
	}, [])

	return (
		<div className='pt-2'>
			<p className='h3 fw-bold text-muted'>Settings</p>
			<div className="shadow p-2">
				<Tabs selectedIndex={selectedTab} onSelect={handleTabChange}>
					<TabList>
						<Tab>General</Tab>
						<Tab>SMS</Tab>
					</TabList>

					<TabPanel>
						<GeneralSettings />
					</TabPanel>
					<TabPanel>
						<SMSSettings />
					</TabPanel>
				</Tabs>
			</div>
		</div>
	)
}

export default Settings
