import React, { useState } from 'react'
import { useAuth } from '../../../context/AuthContext'
import { Link, useLocation } from 'react-router-dom'
import Loading from '../../../components/loading/Loading'
import DataTable from 'react-data-table-component'
import { BASE_API_URL } from '../../../utils/constants'
import { saveAs } from 'file-saver'

function NectaResults() {
    const auth = useAuth()
    const location = useLocation()
    const exam = location?.state?.exam

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState("")
    const [variant, setVariant] = useState("success")

    const columns = [
        {
            name: "Analysis",
            selector: row => row.name,
            sortable: true,
            grow: 2,
        },
        {
            name: "Download",
            cell: row => <div className="d-flex flex-wrap">
                <button
                    className="btn btn-sm text-primary fw-bold me-3"
                    onClick={() => downloadResult(row.link, "pdf", row.name)}
                >
                    <span className="me-2">PDF</span>
                    <i className="bi bi-file-earmark-pdf"></i>
                </button>
                <button
                    className="btn btn-sm text-primary fw-bold"
                    onClick={() => downloadResult(row.link, "excel", row.name)}
                >
                    <span className="me-2">Excel</span>
                    <i className="bi bi-file-earmark-spreadsheet"></i>
                </button>
            </div>,
            sortable: false
        }
    ]

    const downloadResult = async (link, type, name) => {
        setIsLoading(true)
        setMessage("")

        try {
            const response = await fetch(`${BASE_API_URL}/${link}?format=${type}`, {
                headers: {
                    "x-access-token": auth.token
                }
            })

            if (!response.ok) {
                const data = await response.json()
                setMessage(data.message)
                setVariant("warning")
                return
            }

            let extension = "pdf"
            if (type === "excel") {
                extension = "xlsx"
            }
            
            const filename = `MSSIS ${exam?.type} ${name}.${extension}`
            const blob = await response.blob()
            saveAs(blob, filename)
        }
        catch (error) {
            setMessage("Failed to download results analysis. Pleas try again.")
            setVariant("danger")
        }
        finally {
            setIsLoading(false)
        }
    }

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted'>{exam?.name}</p>
            <div className="shadow rounded-0 p-2 mb-3">
                <p className='h4 fw-bold text-muted'>Exam Details</p>
                <div className=''>
                    <p className='text-muted'>
                        Exam Type: <span className='fw-bold'>{exam?.type}</span>
                    </p>
                    <p className='text-muted'>
                        Start Date: <span className='fw-bold'>{exam?.start_date}</span>
                    </p>
                    <p className='text-muted'>
                        End Date: <span className='fw-bold'>{exam?.end_date}</span>
                    </p>
                </div>
            </div>

            {
                message &&
                <div className={`alert alert-${variant} p-2`} role="alert">
                    {message}
                </div>
            }

            {
                isLoading ?
                    (
                        <div className='text-center my-2'>
                            Loading... <Loading />
                        </div>
                    )
                    :
                    (
                        <div className="shadow rounded-0 p-2 mb-3">
                            <p className='h4 fw-bold text-muted'>Download NECTA Analysis</p>
                            <DataTable
                                columns={columns}
                                data={[
                                    {
                                        name: "District School Ranking",
                                        link: `necta/results/export/ranking/district/${exam?.id}`
                                    }
                                ]}
                            />
                        </div>
                    )
            }
        </div>
    )
}

export default NectaResults
