import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../context/AuthContext'
import { Link, useLocation, useParams } from 'react-router-dom'
import { BASE_API_URL } from '../../../utils/constants'
import Loading from '../../../components/loading/Loading'
import DataTable from 'react-data-table-component'
import TeachingProgressPie from '../../../components/charts/TeachingProgressPie'

function ViewSubTopics() {
    const auth = useAuth()
    const params = useParams()
    const topicId = params.topicId
    const location = useLocation()
    const form = location?.state.form
    const subject = location?.state.subject
    const school = location?.state.school

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState("")
    const [variant, setVariant] = useState("success")

    const [topic, setTopic] = useState(location?.state.topic)

    const columns = [
        {
            name: 'S/N',
            selector: (row, index) => index + 1,
            grow: 0,
        },
        {
            name: 'Topic Name',
            selector: row => row.name,
            sortable: true
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true
        },
        {
            name: 'Actions',
            cell: row => <div>
                <Link
                    to={`${row.id}`}
                    state={{ form: form, subject: subject, topic: topic, school: school }}
                    className='btn btn-sm btn-primary rounded-0'>
                    <i className='bi bi-eye-fill fs-6 mx-1'></i>
                </Link>
            </div>,
            button: true
        }
    ]

    const fetchTopic = async () => {
        setIsLoading(true)
        // setMessage('')

        try {
            let url = `${BASE_API_URL}/subject-topic/${topicId}`
            if (["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO"].includes(auth.user.role)) {
                url += `?school=${school.id}`
            }

            const response = await fetch(url, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setTopic(data.topic)
            }
        }
        catch (error) {
            setMessage('Failed to fetch topic details. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchTopic()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted'>Subject Topic</p>
            <p className='fw-bold px-1'>
                {form?.name} {subject?.name}
                <br />
                {topic?.name}
            </p>

            {
                message &&
                <div className={`alert alert-${variant} p-2`} role="alert">
                    {message}
                </div>
            }

            {
                isLoading ?
                    <div className="text-center">
                        Loading... <Loading />
                    </div>
                    :
                    <>
                        <div className="shadow p-2 mb-3 row mx-0">
                            <div className="col-lg-6">
                                <span className='fs-5 text-muted'>
                                    Teaching Status: <strong>{topic?.status || "Not Taught"}</strong>
                                    <br />
                                    Approval Status: <strong>{topic?.approval_status || "Not Approved"}</strong>
                                </span>
                                <br />
                                <p>
                                    <span className='fs-5 text-muted'>
                                        Sub Topics Taught: <strong>{topic?.sub_topics_taught || 0}</strong>
                                        <br />
                                        Sub Topics In Progress: <strong>{topic?.sub_topics_in_progress || 0}</strong>
                                        <br />
                                        Sub Topics Not Taught: <strong>{topic?.sub_topics_not_taught || 0}</strong>
                                    </span>
                                </p>
                            </div>
                            <div className="col-lg-6 my-0 py-0" style={{ maxHeight: "24rem" }}>
                                <TeachingProgressPie
                                    values={[topic?.sub_topics_taught, topic?.sub_topics_in_progress, topic?.sub_topics_not_taught]}
                                />
                            </div>
                        </div>

                        <div className="shadow p-2 mb-3">
                            <p className="h5 text-muted">Select Sub Topic</p>
                            <DataTable
                                columns={columns}
                                data={topic.sub_topics}
                                highlightOnHover
                                noDataComponent={"No sub topics found."}
                            />
                        </div>
                    </>
            }
        </div>
    )
}

export default ViewSubTopics
