import React, { useEffect, useState } from 'react'
import FacilitiesTable from '../../components/facilities/FacilitiesTable'

function SchoolFacilities() {
    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted'>School Facilities</p>

            <div className="shadow p-2 rounded-0">
                <FacilitiesTable />
            </div>
        </div>
    )
}

export default SchoolFacilities
