import React, { useEffect, useState } from 'react'
import RoleOffice from '../../components/offices/RoleOffice'
import Loading from '../../components/loading/Loading'
import { useAuth } from '../../context/AuthContext'
import { useLocation } from 'react-router-dom'
import { BASE_API_URL } from '../../utils/constants'
import DataTable from 'react-data-table-component'
import ConfirmationDialog from '../../components/dialogs/ConfirmationDialog'

function ViewClass() {
    const auth = useAuth()
    const location = useLocation()

    const [isLoading, setIsLoading] = useState(true)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')
    const [isEditing, setIsEditing] = useState(false)
    const [showDialog, setShowDialog] = useState(false)

    const [form, setForm] = useState(location.state.form)
    const [subjects, setSubjects] = useState([])
    const [coreSubjects, setCoreSubjects] = useState([])
    const [teachers, setTeachers] = useState([])
    const [teacher, setTeacher] = useState([])
    const [newTeachers, setNewTeachers] = useState([{}])
    const [toDelete, setToDelete] = useState({})

    const class_teacher_columns = [
        {
            name: 'S/N',
            cell: (row, index) => index + 1,
            sortable: false,
            grow: 0,
        },
        {
            name: 'Name of Staff',
            selector: row => `${row.firstname} ${row.lastname}`,
            sortable: true,
        },
        {
            name: 'Start Date',
            selector: row => row.date_started,
            sortable: true,
        },
        {
            name: 'End Date',
            selector: row => row.date_ended,
            sortable: true,
        },
        {
            name: 'Action',
            cell: row => (
                <button
                    type='button'
                    className='btn btn-sm btn-danger rounded-0'
                    onClick={() => {
                        setToDelete(row)
                        setShowDialog(true)
                    }}
                >
                    <span className="me-2">Remove</span>
                    <i className='bi bi-trash'></i>
                </button>
            ),
        }
    ]

    const fetchSubjects = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/subjects`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()

            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setSubjects(data.subjects)
            }
        } catch (error) {
            setMessage('Failed to fetch subjects. Please try again.')
            setVariant('danger')
        } finally {
            setIsLoading(false)
        }
    }

    const fetchClass = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/classes/${form.id}`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()

            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setForm(data.class)
                setCoreSubjects(data.core_subjects.map(subject => subject.id))
                setTeacher(data.class_teachers)
            }
        } catch (error) {
            setMessage('Failed to fetch class. Please try again.')
            setVariant('danger')
        } finally {
            setIsLoading(false)
        }
    }

    const fetchTeachers = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/employees`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setTeachers(data.employees)
            }
        } catch (error) {
            setMessage("Failed to fetch classes. Please try again.")
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleUpdate = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/classes/${form.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({
                    coreSubjects,
                    newTeachers
                })
            })

            const data = await response.json()

            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setMessage(data.message)
                setVariant('success')
                fetchClass()
            }
        } catch (error) {
            setMessage('Failed to update class. Please try again.')
            setVariant('danger')
        } finally {
            setIsLoading(false)
        }
    }

    const handleDelete = async (teacherId) => {
        setIsLoading(true)
        setMessage('')
        setShowDialog(false)

        try {
            const response = await fetch(`${BASE_API_URL}/classes/${form.id}/teacher/${teacherId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
            })

            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setMessage(data.message)
                setVariant('success')
                fetchClass()
            }
        } catch (error) {
            setMessage('Failed to delete class teacher. Please try again.')
            setVariant('danger')
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchClass()
        if (!["Admin"].includes(auth.user.role)) {
            fetchSubjects()
            fetchTeachers()
        }
    }, [])

    return (
        <div className='pt-2'>
            <div className="d-flex align-items-center justify-content-between mb-3">
                <p className='h3 fw-bold text-muted mb-3'>Class Details</p>
                <RoleOffice roles={["Headmaster", "Second Master", "Academic Master"]} element={
                    <button
                        className='btn btn-primary btn-sm fw-bold rounded-0'
                        onClick={() => setIsEditing(!isEditing)}
                    >
                        {
                            isEditing ?
                                <>
                                    <span className="me-2">Cancel</span>
                                    <i className='bi bi-x-lg'></i>
                                </> :
                                <>
                                    <span className="me-2">Edit</span>
                                    <i className='bi bi-pencil-square'></i>
                                </>
                        }
                    </button>
                } />
            </div>

            <ConfirmationDialog
                open={showDialog}
                onClose={() => {
                    setShowDialog(false)
                    setIsLoading(false)
                }}
                onConfirm={() => {
                    handleDelete(toDelete.id)
                }}
                isLoading={isLoading}
                title='Delete Class Teacher'
                message={`Are you sure you want to remove the class teacher?`}
            />

            {
                isLoading ?
                    <div className="text-center">
                        Loading... <Loading />
                    </div> :
                    <div className='shadow p-2 mb-4'>
                        {
                            message &&
                            <div className={`alert alert-${variant} p-2`} role="alert">
                                {message}
                            </div>
                        }
                        <form onSubmit={handleUpdate}>
                            <div className='row'>
                                <div className='col-lg-4 mb-3'>
                                    <div className='form-group'>
                                        <label htmlFor='name' className='form-label'>Class Name</label>
                                        <input
                                            type='text'
                                            id='name'
                                            className='form-control disabled'
                                            required
                                            disabled
                                            value={form.name}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-4 mb-3'>
                                    <div className='form-group'>
                                        <label htmlFor='level' className='form-label'>Class Level</label>
                                        <input
                                            type='text'
                                            id='level'
                                            className='form-control disabled'
                                            required
                                            disabled
                                            value={form.level}
                                        />
                                    </div>
                                </div>
                            </div>
                            <RoleOffice roles={["Headmaster", "Second Master", "Academic Master"]} element={
                                <>
                                    <div className="my-3">
                                        <p className='mb-3'>
                                            Update {form.name} Subjects
                                        </p>
                                        <div className="row mx-1">
                                            <div className="col-12 col-lg-auto me-2 mb-2">
                                                <input
                                                    type="checkbox"
                                                    id="check_all"
                                                    className='form-radio mb-0 me-2'
                                                    onChange={e => {
                                                        if (e.target.checked) {
                                                            setCoreSubjects(subjects.map(subject => subject.id))
                                                        } else {
                                                            setCoreSubjects([])
                                                        }
                                                    }}
                                                />
                                                <label htmlFor="check_all" className='form-label text-primary'>
                                                    Select All ({coreSubjects.length})
                                                </label>
                                            </div>
                                            {
                                                subjects && subjects.map((subject, index) => (
                                                    <div key={index} className="col-12 col-lg-auto me-2 mb-2 px-2 py-1 border rounded-0">
                                                        <input
                                                            type="checkbox"
                                                            name="subjects"
                                                            id={subject.id}
                                                            className='form-radio mb-0 me-2'
                                                            value={subject.id}
                                                            checked={coreSubjects.includes(subject.id)}
                                                            onChange={e => {
                                                                if (e.target.checked) {
                                                                    setCoreSubjects([...coreSubjects, subject.id])
                                                                } else {
                                                                    setCoreSubjects(coreSubjects.filter(id => id !== subject.id))
                                                                }
                                                            }}
                                                        />
                                                        <label htmlFor={subject.id} className='mb-0 form-label'>
                                                            {subject.name}
                                                        </label>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>


                                    <div className="my-3">
                                        <p className='mb-3'>
                                            Update Class Teachers
                                        </p>
                                        {
                                            auth.user.role != "DSEO" &&
                                            <div className="my-3">
                                                <DataTable
                                                    columns={class_teacher_columns}
                                                    data={teacher}
                                                    noDataComponent={"No class teachers found"}
                                                />
                                            </div>
                                        }
                                        {
                                            isEditing &&
                                            <>
                                                <div className="row my-3">
                                                    {
                                                        newTeachers && newTeachers.map((teach, index) => (
                                                            <div className='col-lg-4 mb-3'>
                                                                <div className='form-group'>
                                                                    <label htmlFor='teacher' className='form-label'>New Class Teacher {index + 1}</label>
                                                                    <select
                                                                        className='form-select'
                                                                        id='teacher'
                                                                        disabled={isLoading || !isEditing}
                                                                        value={teach}
                                                                        onChange={e => {
                                                                            const newValue = e.target.value
                                                                            const updatedTeachers = newTeachers.map((t, i) => (i === index ? newValue : t))
                                                                            setNewTeachers(updatedTeachers)
                                                                        }}>
                                                                        <option value=''>Select Class Teacher</option>
                                                                        {
                                                                            teachers && teachers.map((teach, i) => (
                                                                                <option key={i} value={teach.id}>
                                                                                    {teach.firstname} {teach.lastname}
                                                                                </option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                                {
                                                    isEditing &&
                                                    <div className='mb-3 text-end'>
                                                        <button
                                                            type='button'
                                                            className='btn btn-secondary btn-sm px-2 rounded-0 me-2'
                                                            onClick={() => setNewTeachers([
                                                                ...newTeachers, {}
                                                            ])}
                                                        >
                                                            <span className='me-2'>Add Teacher</span>
                                                            <i className='bi bi-plus-circle'></i>
                                                        </button>
                                                    </div>
                                                }
                                            </>
                                        }
                                    </div>
                                </>
                            } />

                            <div className="">
                                {
                                    message &&
                                    <div className={`alert alert-${variant} p-2`} role="alert">
                                        {message}
                                    </div>
                                }
                            </div>
                            {
                                isEditing &&
                                <div className="text-end mb-3">
                                    <button type='submit' className='btn btn-primary px-4 rounded-0' disabled={isLoading}>
                                        {
                                            isLoading ?
                                                <>Updating... <Loading /></> :
                                                <>
                                                    <span className="me-2">
                                                        Update
                                                    </span>
                                                    <i className='bi bi-floppy2-fill'></i>
                                                </>
                                        }
                                    </button>
                                </div>
                            }
                        </form>
                    </div>
            }
        </div>
    )
}

export default ViewClass
