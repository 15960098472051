import React, { Fragment } from 'react'
import '../results/table-styles.css'
import { useAuth } from '../../context/AuthContext'

function SchoolSubjectTopicCoverage({ coverage }) {
    const auth = useAuth()
    let forms = auth.user.level === "PRIMARY" ?
        ["Class I", "Class II", "Class III", "Class IV", "Class V", "Class VI", "Class VII"]
        :
        ["Form I", "Form II", "Form III", "Form IV"]

    return (
        <div className="table-responsive">
            <table className="table table-striped table-bordered border-dark text-center table-warning table-sm table-hover">
                <thead className='align-middle'>
                    <tr>
                        <th rowSpan={3}>S/N</th>
                        <th rowSpan={3}>SUBJECTS</th>
                        <th colSpan={3 * forms.length}>CLASSES</th>
                        <th rowSpan={3} className="vertical-text">Total Topics <br /> Covered</th>
                        <th rowSpan={3} className="vertical-text">Overall Coverage %</th>
                        <th rowSpan={3} className="vertical-text">Position</th>
                    </tr>
                    <tr>
                        {
                            forms.map((form, index) => (
                                <th colSpan={3} key={index}>{form}</th>
                            ))
                        }
                    </tr>
                    <tr>
                        {
                            forms.map((form, index) =>
                                <Fragment key={index}>
                                    <th>Subject Teacher</th>
                                    <th className="vertical-text">Topics <br /> Covered</th>
                                    <th className="vertical-text">Coverage %</th>
                                </Fragment>
                            )
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        coverage.map((cov, index) =>
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td className='text-start'>{cov?.subject?.name.toUpperCase()}</td>
                                {
                                    forms.map((form, idx) =>
                                        <Fragment key={idx}>
                                            <td className='text-start'>{cov[form] && cov[form]?.teacher}</td>
                                            <td>{cov[form] && cov[form]?.total_topics_covered}</td>
                                            <td>{cov[form] && cov[form]?.coverage_percent}</td>
                                        </Fragment>
                                    )
                                }
                                <td>{cov?.overall_topics_covered}</td>
                                <td>{cov?.overall_coverage_percent}</td>
                                <td>{cov?.position}</td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </div>
    )
}

export default SchoolSubjectTopicCoverage
