import React, { useState } from 'react'
import logo from '../assets/mssis.png'
import Loading from '../components/loading/Loading'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
import { BASE_API_URL } from '../utils/constants'

function Login() {
    const auth = useAuth()
    const navigate = useNavigate()
    const location = useLocation()

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState(location.state ? location.state.message : '')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setMessage('')

        try {
            const resp = await fetch(`${BASE_API_URL}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username, password })
            })

            const data = await resp.json()
            if (!resp.ok) {
                setMessage(data.message)
                setIsLoading(false)
                return
            }

            auth.login(data.token, data.refresh_token)
            navigate(location.state?.from ? location.state.from : '/')
        }
        catch (error) {
            setMessage("Something went wrong. Please try again.")
        }
        setIsLoading(false)
    }

    if (auth.isLoggedIn()) {
        return <Navigate to="/" />
    }
    else {
        return (
            <div className="container my-5 pt-4">
                <div className="row d-flex align-items-center justify-content-center">
                    <div className="col-12 col-lg-6 text-center mb-3">
                        <img src={logo} alt="MSSIS Logo" style={{ width: "120px" }} className="img-fluid" />
                        <br />
                        <h1 className="text-muted h3">
                            Modern Streamlined Schools' Information System (MSSIS)
                        </h1>
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <h2 className="text-muted h4 mb-3">Welcome to MSSIS, login</h2>
                        <div className="my-2 text-center">
                            {
                                message && <span className="text-danger">{message}</span>
                            }
                        </div>
                        <form className="mx-2" onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="username" className="form-label fw-bold">Username</label>
                                <input
                                    type="text"
                                    name="username"
                                    id="username"
                                    className="form-control rounded-0"
                                    required
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="password" className="form-label fw-bold">Password</label>
                                <input
                                    type="password"
                                    name="password"
                                    id="password"
                                    className="form-control rounded-0"
                                    required
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <div className="mb-2 text-end text-primary">
                                <Link to={"/public/password-reset"}>Recover password</Link>
                            </div>
                            <div className="mb-3">
                                <button type="submit" className="btn btn-primary w-100 rounded-0 fw-bold">
                                    {
                                        isLoading ?
                                            <>
                                                Logging in... <Loading />
                                            </> :
                                            <>
                                                Login
                                            </>
                                    }
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default Login
