import React, { useState } from 'react'
import './NavBar.css'
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { BASE_API_URL } from '../../utils/constants';
import RoleOffice from '../offices/RoleOffice';
import LevelSplit from '../offices/LevelSplit';
import ContextSwitch from './ContextSwitch';

function NavBar() {
	const auth = useAuth()
	const user = auth.user
	const navigate = useNavigate()

	const [isLoading, setIsLoading] = useState(false)

	const toggleSideBar = () => {
		const toggle = document.getElementById('header-toggle');
		const nav = document.getElementById('nav-bar');
		const bodypd = document.getElementById('body-pd');
		const headerpd = document.getElementById('header');

		// Validate that all variables exist
		if (toggle && nav && bodypd && headerpd) {
			// show navbar
			nav.classList.toggle('show');
			// change icon
			toggle.classList.toggle('bi-x');
			// add padding to body
			bodypd.classList.toggle('body-pd');
			// add padding to header
			headerpd.classList.toggle('body-pd');
		}
	}

	return (
		<>
			<header className="header" id="header">
				<div className="header_toggle" onClick={toggleSideBar}>
					<i className='bi bi-list' id="header-toggle"></i>
				</div>
				<div className='d-flex align-items-center justify-content-end flex-fill me-4'>
					<span className='fw-bold m-1'>
						[{auth?.selected_academic_year}]&nbsp;
						{
							user.school ?
								<>
									{user.school.name} <LevelSplit primary={"Primary"} ordinary={"Secondary"} /> School
								</> :
								user.aek_ward ?
									<>
										[{auth.user.level === "PRIMARY" ? "Primary" : "Secondary"}]
										AEK {user.aek_ward.name}
									</>
									:
									<>
										{
											user.role === "Admin" ?
												<>
													Admin
												</>
												:
												<LevelSplit primary={"DPPEO Office"} ordinary={"DSEO Office"} />
										}
									</>
						}
					</span>
					<div className='header_bell ms-3'>
						<i className='bi bi-bell-fill fs-2'>
							<span className="bell-badge"></span>
						</i>
					</div>
				</div>
				<div className="header_img text-center fw-bold fs-4 link" data-bs-toggle="collapse" data-bs-target="#userMenu">
					{
						user.profile ? (
							<img
								src={`${BASE_API_URL}${user.profile}`}
								alt='profile'
								className='rounded-circle'
							/>
						) :
							(
								<i className='bi bi-person-fill text-white'></i>
							)
					}
				</div>
			</header>
			<div className='collapse user_dropdown' id='userMenu'>
				<div className='nav_link link mt-2'>
					{user.school ? user.school.display_role : user.role}
					: {user.firstname} {user.lastname}
				</div>
				<ContextSwitch isLoading={isLoading} setIsLoading={setIsLoading} orientation={"vertical"} />
				<RoleOffice roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO", "AEK", "Headmaster", "Second Master"]} element={
					<Link to="/settings" className='nav_link link'>
						<i className='bi bi-gear nav_icon'></i>
						<span className='ms-2 nav_text'>Settings</span>
					</Link>
				} />
				<Link to="/settings/profile" className='nav_link link'>
					<i className='bi bi-person nav_icon'></i>
					<span className='ms-2 nav_text'>My Profile</span>
				</Link>
				<div className='nav_link link' onClick={() => {
					auth.logout()
					navigate("/public/login")
				}}>
					<i className='bi bi-box-arrow-right nav_icon'></i>
					<span className='ms-2 nav_text'>Logout</span>
				</div>
			</div>
		</>
	)
}

export default NavBar
