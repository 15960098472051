import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'
import Loading from '../../components/loading/Loading'

function SMSManagePrices() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState("")
    const [variant, setVariant] = useState("success")

    const [priceRanges, setPriceRanges] = useState([])
    const [prices, setPrices] = useState([{ "price": "", "min_amount": "", "max_amount": "" }])

    const fetchPrices = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/sms/prices`, {
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant("warning")
            }
            else {
                setPriceRanges(data.prices)
            }
        }
        catch (error) {
            setMessage("Failed to fetch SMS prices. Please try again.")
            setVariant("danger")
        }
        finally {
            setIsLoading(false)
        }
    }

    const updatePrices = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setMessage("")

        try {
            const response = await fetch(`${BASE_API_URL}/sms/prices`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": auth.token
                },
                body: JSON.stringify({ "prices": prices })
            })

            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant("warning")
            }
            else {
                setMessage(data.message)
                setVariant("success")
                setPriceRanges(data.prices)
            }
        }
        catch (error) {
            setMessage("Failed to update prices. Please try again.")
            setVariant("danger")
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchPrices()
    }, [])

    return (
        <div className="mb-3">
            <p className="h5 fw-bold text-muted">SMS Prices</p>
            {
                message &&
                <div className={`alert alert-${variant} p-2`} role="alert">
                    {message}
                </div>
            }

            {
                isLoading &&
                <div className="text-center">
                    <Loading />
                </div>
            }

            <div className="table-responsive mb-3">
                <table className="table nowrap">
                    <tbody>
                        <tr>
                            <td><strong>Amount</strong></td>
                            {
                                priceRanges.map((pr, index) => (
                                    <td className="text-center">{pr.min_amount} - {pr.max_amount}</td>
                                ))
                            }
                        </tr>
                        <tr>
                            <td><strong>Price (Tsh)</strong></td>
                            {
                                priceRanges.map((pr, index) => (
                                    <td className="text-center">{pr.price}</td>
                                ))
                            }
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="mb-3">
                <p className="h5 fw-bold text-muted">Update SMS Prices</p>
                <form onSubmit={updatePrices}>
                    {
                        prices.map((price, index) => (
                            <div key={index} className="row mb-4">
                                <div className="col-lg-4">
                                    <label htmlFor="price" className="form-label">Price</label>
                                    <input
                                        type="number"
                                        id="price"
                                        className="form-control rounded-0"
                                        placeholder="30"
                                        disabled={isLoading}
                                        value={price.price}
                                        onChange={e => {
                                            let newPrices = [...prices]
                                            newPrices[index].price = e.target.value
                                            setPrices(newPrices)
                                        }}
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <label htmlFor="min_amount" className="form-label">Minimum Amount</label>
                                    <input
                                        type="number"
                                        id="min_amount"
                                        className="form-control rounded-0"
                                        placeholder="1000"
                                        disabled={isLoading}
                                        value={price.min_amount}
                                        onChange={e => {
                                            let newPrices = [...prices]
                                            newPrices[index].min_amount = e.target.value
                                            setPrices(newPrices)
                                        }}
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <label htmlFor="max_amount" className="form-label">Maximum Amount</label>
                                    <input
                                        type="number"
                                        id="max_amount"
                                        className="form-control rounded-0"
                                        placeholder="9999"
                                        disabled={isLoading}
                                        value={price.max_amount}
                                        onChange={e => {
                                            let newPrices = [...prices]
                                            newPrices[index].max_amount = e.target.value
                                            setPrices(newPrices)
                                        }}
                                    />
                                </div>
                            </div>
                        ))
                    }
                    <div className="mb-3 text-end">
                        <button
                            className="btn btn-secondary rounded-0 me-2"
                            type='button'
                            disabled={isLoading}
                            onClick={() => {
                                setPrices([...prices, { "price": "", "min_amount": "", "max_amount": "" }])
                            }}
                        >
                            <span className="me-2">Add Price</span>
                            <i className="bi bi-plus-circle"></i>
                        </button>
                        <button className="btn btn-primary rounded-0 me-2" type='submit' disabled={isLoading}>
                            <span className="me-2">Upadate Prices</span>
                            <i className="bi bi-floppy2"></i>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default SMSManagePrices
