import React, { useEffect, useState } from 'react'
import { BASE_API_URL } from '../../../utils/constants'
import Loading from '../../../components/loading/Loading'
import { saveAs } from 'file-saver'

function NectaResultsAnalysisRequest() {
    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState("")
    const [variant, setVariant] = useState("success")

    const [regions, setRegions] = useState([])
    const [examType, setExamType] = useState("")
    const [level, setLevel] = useState("")
    const [region, setRegion] = useState("")
    const [type, setType] = useState("")
    const [phone, setPhone] = useState("")

    const fetchRegions = async () => {
        setIsLoading(true)
        setMessage("")

        try {
            const response = await fetch(`${BASE_API_URL}/necta/regions`)
            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant("danger")
                return
            }
            setRegions(data.regions)
        }
        catch (error) {
            setMessage("Something went wrong. Please try again.")
            setVariant("danger")
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleSubmit = async (action) => {
        setIsLoading(true)
        setMessage("")

        try {
            const response = await fetch(`${BASE_API_URL}/necta/results/analysis/${action}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ examType, level, region, type, phone })
            })

            if (action === "download" && response.ok) {
                let filename = `MSSIS ${examType} ${level} ${type}`.toUpperCase()
                filename = `${filename}.pdf`
                const blob = await response.blob()
                saveAs(blob, filename)
                return
            }
            
            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant("danger")
                return
            }

            setMessage(data.message)
            setVariant("success")
        }
        catch(error) {
            console.log(error)
            setMessage("Something went wrong. Please try again.")
            setVariant("danger")
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchRegions()
    }, [])

    return (
        <div className="container mt-4 mb-5">
            <h1 className="text-muted h3">
                NECTA Results Analysis
            </h1>
            <div className="mb-3">
                <span className="fw-bold">How to use</span>
                <ol>
                    <li>Choose exam type (e.g CSEE)</li>
                    <li>Select the level of analysis you want. (National or Regional)</li>
                    <li>Select the type of analysis for that level. (For regional level you also have to select the region)</li>
                    <li>Fill in your phone number, <strong>one that can process mobile payment</strong> and click <i>Pay Now</i>.</li>
                    <li><strong>On your mobile phone:</strong> complete the payment by filling in your mobile payment pin and confirm.</li>
                    <li>After you have paid, click the <i>Download</i> button to get your PDF.</li>
                </ol>
            </div>
            <div className="mb-3">
                <span className="fw-bold">Note: </span>
                <br />
                <ul className="text-info">
                    <li>Every analysis costs Tsh. 3000/= only.</li>
                    <li>Only school candidates' results are included in these analysis. (Private candidates are not included)</li>
                </ul>
            </div>

            {
                message &&
                <div className={`alert alert-${variant} p-2`} role="alert">
                    {message}
                </div>
            }

            <div className="mb-3">
                <form onSubmit={e => {
                    e.preventDefault()
                    handleSubmit("request")
                }}>
                    <div className="mb-3 form-group">
                        <label htmlFor="examType" className="form-label fw-bold">Exam Type</label>
                        <select
                            className="form-select rounded-0"
                            id="examType"
                            required
                            disabled={isLoading}
                            value={examType}
                            onChange={e => setExamType(e.target.value)}
                        >
                            <option value="">Choose exam type</option>
                            <option value="csee">CSEE</option>
                        </select>
                    </div>
                    <div className="mb-3 form-group">
                        <label htmlFor="level" className="form-label fw-bold">Level</label>
                        <select
                            className="form-select rounded-0"
                            id="level"
                            required
                            disabled={isLoading}
                            value={level}
                            onChange={e => setLevel(e.target.value)}
                        >
                            <option value="">Choose results level</option>
                            <option value="national">National</option>
                            <option value="regional">Regional</option>
                        </select>
                    </div>
                    {
                        level === "regional" &&
                        <div className="mb-3 form-group">
                            <label htmlFor="region" className="form-label fw-bold">Region</label>
                            <select
                                className="form-select rounded-0"
                                id="region"
                                required
                                disabled={isLoading}
                                value={region}
                                onChange={e => setRegion(e.target.value)}
                            >
                                <option value="">Choose center region</option>
                                {
                                    regions.map((region, index) => (
                                        <option key={index} value={region}>{region}</option>
                                    ))
                                }
                            </select>
                        </div>
                    }
                    <div className="mb-3 form-group">
                        <label htmlFor="type" className="form-label fw-bold">Type</label>
                        <select
                            className="form-select rounded-0"
                            id="type"
                            required
                            disabled={isLoading}
                            value={type}
                            onChange={e => setType(e.target.value)}
                        >
                            <option value="">Choose results analysis type</option>
                            <option value="school_ranking">School Ranking</option>
                            <option value="school_ranking_subjectwise">School Ranking Subjectwise</option>
                            <option value="students_ranking">Students Ranking</option>
                            <option value="teachers_ranking">Teachers Ranking</option>
                            <option value="teachers_ranking_subjectwise">Teachers Ranking Subjectwise</option>
                        </select>
                    </div>
                    <div className="mb-3 form-group">
                        <label htmlFor="phone" className="form-label fw-bold">Phone Number</label>
                        <input
                            type="text"
                            className="form-control rounded-0"
                            id="phone"
                            required
                            placeholder="E.g 0759640777"
                            pattern="0[67][0-9]{8}"
                            disabled={isLoading}
                            value={phone}
                            onChange={e => setPhone(e.target.value)}
                        />
                    </div>
                    {
                        message &&
                        <div className={`alert alert-${variant} p-2`} role="alert">
                            {message}
                        </div>
                    }
                    <div className="d-flex flex-wrap align-items-center justify-content-end mb-3">
                        <button
                            type="submit"
                            className="btn btn-warning rounded-0 fw-bold px-4 me-3"
                            disabled={isLoading}
                        >
                            {
                                isLoading ? <>Processing... <Loading /></> : "Pay Now"
                            }
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary rounded-0 fw-bold px-4"
                            disabled={isLoading}
                            onClick={() => handleSubmit("download")}
                        >
                            {
                                isLoading ? <>Processing... <Loading /></> : "Download"
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default NectaResultsAnalysisRequest
