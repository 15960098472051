import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../context/AuthContext'
import DataTable from 'react-data-table-component'
import { useParams } from 'react-router-dom'
import { BASE_API_URL } from '../../../utils/constants'
import Loading from '../../../components/loading/Loading'

function DeliveryReport() {
    const auth = useAuth()
    const params = useParams()
    const usageId = params.smsUsageId

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState("")
    const [variant, setVariant] = useState("success")

    const [report, setReport] = useState({})

    const columns = [
        {
            name: "S/N",
            selector: (row, index) => index + 1,
            grow: 0,
        },
        {
            name: "Name",
            selector: row => row?.name,
            sortable: true,
        },
        {
            name: "Phone",
            selector: row => row?.phone,
            sortable: true,
        },
        {
            name: "Message",
            selector: row => row?.message,
            sortable: false,
            grow: 3,
            wrap: true,
        },
        {
            name: "Status",
            cell: row => <div className="d-flex align-items-center flex-wrap">
                <span className="me-2">{row?.status?.text}</span>
                <i className={`bi bi-${row?.status?.icon} text-${row?.status?.color} fs-6`}></i>
            </div>
        },
        {
            name: "Action",
            cell: row => (
                <div className='d-flex nowrap'>
                    <button
                        className='btn btn-sm btn-primary rounded-0 me-2'
                        disabled={row?.action !== "RESEND" || isLoading}
                        onClick={() => handleResend(row.id)}
                    >
                        <span className="me-2">Resend</span>
                        <i className='bi bi-arrow-repeat fs-6'></i>
                    </button>
                </div>
            ),
            sortable: false,
            wrap: false,
        }
    ]

    const fetchReport = async() => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/sms/delivery-report/${usageId}`, {
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant("warning")
            }
            else {
                setReport(data.report)
            }
        }
        catch(error) {
            setMessage("Failed to fetch delivery reports. Please try again.")
            setVariant("danger")
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleResend = async(reportId) => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/sms/resend/${reportId}`, {
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant("warning")
            }
            else {
                setReport(data.report)
            }
        }
        catch(error) {
            setMessage("Failed to resend SMS. Please try again.")
            setVariant("danger")
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchReport()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted'>Delivery Report</p>
            <p>
                <span className="me-3">
                    <strong>Recepients: </strong> {report?.summary?.recepients}
                </span>
                <span className="me-3">
                    <strong>SMS Used: </strong> {report?.summary?.sms_spent}
                </span>
                <span className="me-3">
                    <strong>Delivered: </strong> {report?.summary?.delivered}
                </span>
                <span className="me-3">
                    <strong>Sent: </strong> {report?.summary?.sent}
                </span>
                <span className="me-3">
                    <strong>Failed: </strong> {report?.summary?.failed}
                </span>
                <span className="me-3">
                    <strong>Date Sent: </strong> {report?.summary?.date_sent}
                </span>
            </p>
            <p>
                SMS can be resend only if it was not delivered and 1 hour has passed since it was first sent.
            </p>

            {
                message &&
                <div className={`alert alert-${variant} p-2`} role="alert">
                    {message}
                </div>
            }

            <div className="mb-3 shadow rounded-0 p-2">
                <p className='h4 fw-bold text-muted'>Recepients</p>
                <DataTable
                    columns={columns}
                    data={report?.recepients}
                    progressPending={isLoading}
                    progressComponent={<>Loading... <Loading /></>}
                    responsive
                />
            </div>
        </div>
    )
}

export default DeliveryReport
