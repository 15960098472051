import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { Link } from 'react-router-dom'
import { BASE_API_URL } from '../../utils/constants'
import Loading from '../../components/loading/Loading'
import DataTable from 'react-data-table-component'
import RoleOffice from '../../components/offices/RoleOffice'
import { saveAs } from 'file-saver'
import LevelSplit from '../../components/offices/LevelSplit'

function Staffs() {
	const auth = useAuth()

	const [isLoading, setIsLoading] = useState(true)
	const [message, setMessage] = useState('')
	const [variant, setVariant] = useState('success')

	const [currentPage, setCurrentPage] = useState(1)
	const [perPage, setPerPage] = useState(10)
	const [totalStaffs, setTotalStaffs] = useState(0)

	const [searchTerm, setSearchTerm] = useState('')
	const [staffs, setStaffs] = useState([])
	const [fetchedStaffs, setFetchedStaffs] = useState([])

	const [schools, setSchools] = useState([])
	const [filterSchool, setFilterSchool] = useState('')
	const [filterDesignation, setFilterDesignation] = useState('')
	const [filterSex, setFilterSex] = useState('')
	const [filterReversed, setFilterReversed] = useState(false)

	const columns = [
		{
			name: 'S/N',
			selector: (row, index) => perPage * (currentPage - 1) + (index + 1),
			sortable: false,
			grow: 0,
		},
		{
			name: 'Name',
			selector: row => `${row.firstname} ${row.lastname}`,
			grow: 2,
			sortable: true,
		},
		{
			name: 'Check Number',
			selector: row => row.check_number,
			sortable: false,
		},
		{
			name: 'TSC Number',
			selector: row => row.tsc_number,
			sortable: false,
		},
		{
			name: 'Phone',
			selector: row => row.phone,
			sortable: false,
		},
		{
			name: 'Sex',
			selector: row => row.sex,
			sortable: true,
		},
		{
			name: 'Station',
			selector: row => row.station,
			sortable: true,
		},
		{
			name: 'Designation',
			selector: row => row.designation,
			sortable: true,
		},
		{
			name: 'Action',
			cell: row =>
				<div className='d-flex'>
					<RoleOffice roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "Headmaster", "Second Master"]} element={
						<Link
							to={`/staffs/${row.id}/edit`}
							state={{ staff: row }}
							className='btn btn-sm btn-primary rounded-0 me-2'>
							<i className='bi bi-pencil-square fs-6 mx-1'></i>
						</Link>}
					/>
					<Link
						to={`/staffs/${row.id}`}
						state={{ staff: row }}
						className='btn btn-sm btn-primary rounded-0'>
						<i className='bi bi-eye-fill fs-6 mx-1'></i>
					</Link>
				</div>,
			sortable: false,
		}
	]

	const handleSearch = async (e) => {
		e.preventDefault()

		if (searchTerm.length < 1) {
			return
		}

		setIsLoading(true)
		setMessage('')

		try {
			const response = await fetch(`${BASE_API_URL}/employees/search?q=${searchTerm}`, {
				headers: {
					'x-access-token': auth.token
				}
			})

			const data = await response.json()
			if (!response.ok) {
				setMessage(data.message)
				setVariant('warning')
			}
			else {
				setStaffs(data.employees)
				setCurrentPage(data.page)
				setPerPage(data.per_page)
				setTotalStaffs(data.total_employees)
			}
		}
		catch (error) {
			setMessage('Failed to fetch staffs. Please try again.')
			setVariant('danger')
		}
		finally {
			setIsLoading(false)
		}
	}

	const fetchStaffs = async (cp = 1, pp = 10) => {
		setIsLoading(true)
		setMessage('')

		try {
			const response = await fetch(`${BASE_API_URL}/employees?page=${cp}&per_page=${pp}&school=${filterSchool}&designation=${filterDesignation}&sex=${filterSex}&reversed=${filterReversed}`, {
				headers: {
					'x-access-token': auth.token
				}
			})

			const data = await response.json()
			if (!response.ok) {
				setMessage(data.message)
				setVariant('warning')
			}
			else {
				setStaffs(data.employees)
				setFetchedStaffs(data.employees)
				setCurrentPage(data.page)
				setPerPage(data.per_page)
				setTotalStaffs(data.total_employees)
			}
		} catch (error) {
			setMessage('Failed to fetch staffs. Please try again.')
			setVariant('danger')
		} finally {
			setIsLoading(false)
		}
	}

	const fetchSchools = async () => {
		setIsLoading(true)
		setMessage('')

		try {
			const response = await fetch(`${BASE_API_URL}/schools`, {
				headers: {
					'x-access-token': auth.token
				}
			})

			const data = await response.json()
			if (!response.ok) {
				setMessage(data.message)
				setVariant('warning')
			}
			else {
				setSchools(data.schools)
			}
		}
		catch (error) {
			setMessage('Failed to fetch schools. Please try again.')
			setVariant('danger')
		}
		finally {
			setIsLoading(false)
		}
	}

	const handleExport = async (exportType) => {
		setIsLoading(true)
		setMessage('')

		try {
			let url = `${BASE_API_URL}/employees/export/${exportType}?school=${filterSchool}&designation=${filterDesignation}&sex=${filterSex}&reversed=${filterReversed}`
			const response = await fetch(url, {
				headers: {
					'x-access-token': auth.token
				}
			})

			if (!response.ok) {
				const data = await response.json()
				setMessage(data.message)
				setVariant('warning')
				return
			}

			let suff = "MSSIS"
			if (auth.user.school) {
				suff = auth.user.school.name
			}

			let extension = "pdf"
			if (exportType === "excel") {
				extension = "xlsx"
			}

			const filename = `${suff} Staffs Export ${new Date().toISOString().split('T')[0]}.${extension}`

			const blob = await response.blob()
			saveAs(blob, filename)
		}
		catch (error) {
			setMessage('Failed to export staffs. Please try again.')
			setVariant('danger')
		}
		finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		if (["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO", "AEK"].includes(auth.user.role)) {
			fetchSchools()
		}
	}, [])

	useEffect(() => {
		if (searchTerm.length < 1) {
			fetchStaffs(currentPage, perPage)
		}
	}, [currentPage, perPage, searchTerm])

	return (
		<div className='pt-2'>
			<div className="d-flex align-items-center justify-content-between mb-3">
				<p className='h3 fw-bold text-muted'>School Staff Members</p>
				<RoleOffice roles={["DPPAEO", "DPPSNEO", "AEK"]} reverse={true} element={
					<Link to='/staffs/new' className='btn btn-primary btn-sm fw-bold rounded-0'>
						<span className="me-2">Register</span>
						<i className='bi bi-person-plus-fill'></i>
					</Link>
				} />
			</div>

			<form onSubmit={handleSearch} className="body shadow p-2 mb-3 d-flex">
				<input
					type="text"
					className="form-control me-1 rounded-0"
					placeholder="Search staffs..."
					value={searchTerm}
					onChange={e => setSearchTerm(e.target.value)}
				/>
				<button type='submit' className="btn btn-sm btn-primary px-3 fw-bold rounded-0">
					Search
				</button>
			</form>

			{
				message &&
				<div className={`alert alert-${variant} p-2`} role="alert">
					{message}
				</div>
			}

			<div className="my-3 shadow p-2">
				<p className="text-muted fw-bold">Filter Staffs</p>
				<form onSubmit={e => {
					e.preventDefault()
					fetchStaffs(currentPage, perPage)
				}}>
					<div className="row mx-0 ">
						<RoleOffice roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO", "AEK"]} element={
							<div className="col-12 col-lg-3 mb-2">
								<label htmlFor='school' className='form-label'>Working Station</label>
								<select
									id='school'
									className='form-select rounded-0'
									value={filterSchool}
									onChange={e => setFilterSchool(e.target.value)}
								>
									<option value='all'>All Stations</option>
									{
										schools.map(school =>
											<option key={school.id} value={school.id}>{school.name}</option>
										)
									}
								</select>
							</div>
						} />
						<div className="col-12 col-lg-3 mb-2">
							<label htmlFor='designation' className='form-label'>Designation</label>
							<select
								id='designation'
								className='form-select rounded-0'
								value={filterDesignation}
								onChange={e => setFilterDesignation(e.target.value)}
							>
								<option value='all'>All Designations</option>
								{/* <option value="DSEO">DSEO</option> */}
								<option value="Headmaster">
									<LevelSplit primary={"Head Teacher"} ordinary={"Headmaster/Headmistress"} />
								</option>
								<option value="Second Master">
									<LevelSplit primary={"Second Teacher"} ordinary={"Second Master/Mistress"} />
								</option>
								<option value="Academic Master">
									<LevelSplit primary={"Academic Teacher"} ordinary={"Academic Master/Mistress"} />
								</option>
								<option value="Teacher">Teacher</option>
								<option value="Temporary Teacher">Temporary Teacher</option>
							</select>
						</div>
						<div className="col-12 col-lg-3 mb-2">
							<label htmlFor='sex' className='form-label'>Sex</label>
							<select
								id='sex'
								className='form-select rounded-0'
								value={filterSex}
								onChange={e => setFilterSex(e.target.value)}
							>
								<option value='all'>All Sex</option>
								<option value="Female">Female</option>
								<option value="Male">Male</option>
							</select>
						</div>
						<div className="col-12 col-lg-3 mt-2 mb-2 me-0 ms-auto text-end">
							<div className="d-flex align-items-center justify-content-end mt-4">
								<div className="me-2">
									<input
										type='checkbox'
										id='reversed'
										className='form-check-input me-2'
										checked={filterReversed}
										onChange={e => setFilterReversed(e.target.checked)}
									/>
									<label htmlFor='reversed' className='form-label fw-bold'>
										Reversed? {filterReversed ? 'Yes' : 'No'}
									</label>
								</div>
								<button type='submit' className='btn btn-primary rounded-0'>
									<span className="me-2">Filter</span>
									<i className='bi bi-funnel-fill'></i>
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>

			<div className="my-3">
				{
					<DataTable
						title={
							<div className='d-flex flex-wrap align-items-center justify-content-between'>
								<div>{totalStaffs} Staff Members</div>
								<div className="d-flex flex-wrap align-items-center justify-content-between">
									<button className='btn btn-sm btn-primary rounded-0 me-2' onClick={e => handleExport("pdf")}>
										<span className="me-2">Export to PDF</span>
										<i className='bi bi-file-earmark-pdf'></i>
									</button>
									<button className='btn btn-sm btn-primary rounded-0' onClick={e => handleExport("excel")}>
										<span className="me-2">Export to Excel</span>
										<i className='bi bi-file-earmark-spreadsheet'></i>
									</button>
								</div>
							</div>
						}
						columns={columns}
						data={staffs}
						progressPending={isLoading}
						progressComponent={
							<div className="text-center">
								Loading... <Loading />
							</div>
						}
						pagination
						paginationServer
						paginationTotalRows={totalStaffs}
						onChangePage={p => setCurrentPage(p)}
						onChangeRowsPerPage={pp => setPerPage(pp)}
						paginationPerPage={perPage}
						paginationRowsPerPageOptions={[10, 30, 50, 150]}
						noDataComponent={"No staff members found."}
					/>
				}
			</div>
		</div>
	)
}

export default Staffs
