import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { Link } from 'react-router-dom'
import RoleOffice from '../../components/offices/RoleOffice'
import { BASE_API_URL } from '../../utils/constants'
import LevelSplit from '../../components/offices/LevelSplit'
import { saveAs } from 'file-saver'
import Loading from '../../components/loading/Loading'
import DataTable from 'react-data-table-component'

function Repeaters() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState("")
    const [variant, setVariant] = useState("success")

    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(10)
    const [schools, setSchools] = useState([])
    const [forms, setForms] = useState([])
    const [filterSchool, setFilterSchool] = useState("")
    const [filterForm, setFilterForm] = useState("")
    const [filterSex, setFilterSex] = useState("")

    const [totalRepeaters, setTotalRepeaters] = useState([])
    const [repeaters, setRepeaters] = useState([])

    const columns = [
        {
            name: 'S/N',
            selector: (row, index) => perPage * (currentPage - 1) + (index + 1),
            grow: 0,
        },
        {
            name: 'Name',
            selector: row => `${row.firstname} ${row.middlename} ${row.lastname}`,
            sortable: true,
            grow: 2,
        },
        {
            name: 'PREMS No',
            selector: row => row.prems_number,
            sortable: true,
        },
        {
            name: 'Admission No',
            selector: row => row.admission_number,
            sortable: true,
        },
        {
            name: 'Sex',
            selector: row => row.sex,
            sortable: true,
            grow: 0,
        },
        {
            name: 'School',
            selector: row => row.school,
            sortable: true,
        },
        {
            name: 'Class/Form',
            selector: row => row.form,
            sortable: true,
        },
        {
            name: 'Action',
            cell: row =>
                <div className='d-flex'>
                    <Link
                        to={`/students/${row.id}`}
                        state={{ staff: row }}
                        className='btn btn-sm btn-primary rounded-0 me-2'>
                        <i className='bi bi-pencil-square fs-6 mx-1'></i>
                    </Link>
                    <Link
                        to={`/students/${row.id}`}
                        state={{ staff: row }}
                        className='btn btn-sm btn-primary rounded-0'>
                        <i className='bi bi-eye-fill fs-6 mx-1'></i>
                    </Link>
                </div>,
            sortable: false,
        }
    ]

    const fetchSchools = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/schools`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setSchools(data.schools)
            }
        }
        catch (error) {
            setMessage('Failed to fetch schools. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchForms = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/classes`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setForms(data.classes)
            }
        }
        catch (error) {
            setMessage('Failed to fetch forms. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchRepeaters = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const url = `${BASE_API_URL}/students?page=${currentPage}&per_page=${perPage}&school=${filterSchool}&form=${filterForm}&sex=${filterSex}&type=repeater`
            const response = await fetch(url, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setRepeaters(data.students)
                setCurrentPage(data.page)
                setPerPage(data.per_page)
                setTotalRepeaters(data.total_students)
            }
        }
        catch (error) {
            setMessage('Failed to fetch repeater. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleExport = async (exportType) => {
        setIsLoading(true)
        setMessage('')

        try {
            let url = `${BASE_API_URL}/students/export/${exportType}?school=${filterSchool}&form=${filterForm}&sex=${filterSex}&type=repeater`
            const response = await fetch(url, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            if (!response.ok) {
                const data = await response.json()
                setMessage(data.message)
                setVariant('warning')
                return
            }

            let suff = "MSSIS"
            if (auth.user.school) {
                suff = auth.user.school.name
            }

            let extension = "pdf"
            if (exportType === "excel") {
                extension = "xlsx"
            }

            const filename = `${suff} Repeaters Export ${new Date().toISOString().split('T')[0]}.${extension}`

            const blob = await response.blob()
            saveAs(blob, filename)
        }
        catch (error) {
            setMessage('Failed to export repeaters. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchForms()
        if (["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO", "AEK"].includes(auth.user.role)) {
            fetchSchools()
        }
    }, [])

    useEffect(() => {
        fetchRepeaters()
    }, [currentPage, perPage])

    return (
        <div className='pt-2'>
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
                <p className='h3 fw-bold text-muted'>Repeaters</p>
                <div className="d-flex flex-wrap">
                    <RoleOffice
                        roles={["Headmaster", "Second Master", "Academic Master"]}
                        element={
                            <Link to='register' className='btn btn-primary btn-sm fw-bold rounded-0'>
                                <span className="me-2">Register Repeaters</span>
                                <i className='bi bi-person-plus-fill'></i>
                            </Link>
                        }
                    />
                </div>
            </div>

            {
                message &&
                <div className={`alert alert-${variant} p-2`} role="alert">
                    {message}
                </div>
            }

            <div className="my-3 shadow rounded-0 p-2">
                <p className='text-muted fw-bold'>
                    Filter
                </p>
                <form onSubmit={e => {
                    e.preventDefault()
                    fetchRepeaters()
                }}
                    className="mb-3"
                >
                    <div className="row mx-0 ">
                        <RoleOffice roles={["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO", "AEK"]} element={
                            <div className="col-12 col-lg-3 mb-2">
                                <label htmlFor='school' className='form-label'>School</label>
                                <select
                                    id='school'
                                    className='form-select rounded-0'
                                    value={filterSchool}
                                    onChange={e => setFilterSchool(e.target.value)}
                                >
                                    <option value='all'>All Schools</option>
                                    {
                                        schools.map(school =>
                                            <option key={school.id} value={school.id}>{school.name}</option>
                                        )
                                    }
                                </select>
                            </div>
                        } />
                        <div className="col-12 col-lg-3 mb-2">
                            <label htmlFor='form' className='form-label'>
                                <LevelSplit primary={"Class"} ordinary={"Form"} />
                            </label>
                            <select
                                id='form'
                                className='form-select rounded-0'
                                value={filterForm}
                                onChange={e => setFilterForm(e.target.value)}
                            >
                                <option value='all'>
                                    All <LevelSplit primary={"Classes"} ordinary={"Forms"} />
                                </option>
                                {
                                    forms.map(form =>
                                        <option key={form.id} value={form.id}>{form.name}</option>
                                    )
                                }
                            </select>
                        </div>
                        <div className="col-12 col-lg-3 mb-2">
                            <label htmlFor='sex' className='form-label'>Sex</label>
                            <select
                                id='sex'
                                className='form-select rounded-0'
                                value={filterSex}
                                onChange={e => setFilterSex(e.target.value)}
                            >
                                <option value='all'>All Sex</option>
                                <option value="Female">Female</option>
                                <option value="Male">Male</option>
                            </select>
                        </div>
                        <div className="col-12 col-lg-3 mt-2 mb-2 me-0 ms-auto text-end">
                            <button type='submit' className='btn btn-primary rounded-0 mt-4'>
                                <span className="me-2">Filter</span>
                                <i className='bi bi-funnel-fill'></i>
                            </button>
                        </div>
                    </div>
                </form>

                <DataTable
                    title={
                        <div className='d-flex align-items-center justify-content-between'>
                            <span>
                                {totalRepeaters} Repeaters
                            </span>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div className="d-flex flex-wrap align-items-center justify-content-between">
                                    <button className='btn btn-sm btn-primary rounded-0 me-2' onClick={e => handleExport("pdf")}>
                                        <span className="me-2">Export to PDF</span>
                                        <i className='bi bi-file-earmark-pdf'></i>
                                    </button>
                                    <button className='btn btn-sm btn-primary rounded-0' onClick={e => handleExport("excel")}>
                                        <span className="me-2">Export to Excel</span>
                                        <i className='bi bi-file-earmark-spreadsheet'></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                    columns={columns}
                    data={repeaters}
                    progressPending={isLoading}
                    progressComponent={
                        <div className="text-center">
                            Loading... <Loading />
                        </div>
                    }
                    pagination
                    paginationServer
                    paginationTotalRows={totalRepeaters}
                    onChangePage={p => setCurrentPage(p)}
                    onChangeRowsPerPage={pp => setPerPage(pp)}
                    paginationPerPage={perPage}
                    paginationRowsPerPageOptions={[10, 30, 50, 150]}
                    noDataComponent={"No repeaters found"}
                />
            </div>
        </div>
    )
}

export default Repeaters
