import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { useLocation } from 'react-router-dom'
import { BASE_API_URL } from '../../utils/constants'
import Loading from '../../components/loading/Loading'
import DataTable from 'react-data-table-component'
import LevelSplit from '../../components/offices/LevelSplit'
import { saveAs } from 'file-saver'

function TeachingClassStudents() {
    const auth = useAuth()
    const location = useLocation()
    const teachingClass = location.state.teachingClass

    const [isLoading, setIsLoading] = useState(true)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')

    const [students, setStudents] = useState([])

    const students_columns = [
        {
            name: 'S/N',
            selector: (row, index) => index + 1,
            grow: 0,
        },
        {
            name: 'Name',
            selector: row => `${row.firstname} ${row.middlename} ${row.lastname}`,
            sortable: true,
            grow: 2,
        },
        {
            name: 'PREMS No',
            selector: row => row.prems_number,
            sortable: true,
        },
        {
            name: 'Admission No',
            selector: row => row.admission_number,
            sortable: true,
        },
        {
            name: 'Sex',
            selector: row => row.sex,
            sortable: true,
            grow: 0,
        },
        {
            name: 'School',
            selector: row => row.school,
            sortable: true,
        },
        {
            name: 'Class/Form',
            selector: row => row.form,
            sortable: true,
        }
    ]

    const fetchSubjectStudents = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            // per_page=10000, an arbitrary large number to fetch all students
            const response = await fetch(`${BASE_API_URL}/teaching/classes/${teachingClass.id}/students?page=1&per_page=10000`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setStudents(data.students)
            }
        }
        catch (error) {
            setMessage('Failed to fetch students. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const exportStudents = async (format) => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/teaching/classes/${teachingClass.id}/students/export/${format}`, {
                headers: {
                    'x-access-token': auth.token,
                    'Content-Type': 'application/json'
                }
            })

            if (!response.ok) {
                const data = await response.json()
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                let extension = "pdf"
                if (format === "excel") {
                    extension = "xlsx"
                }

                let suffix = "Students"
                if (auth.user.level === "PRIMARY") {
                    suffix = "Pupils"
                }

                const filename = `${teachingClass.form.name} ${teachingClass.subject.name} ${suffix}.${extension}`
                const blob = await response.blob()
                saveAs(blob, filename)
            }
        }
        catch (error) {
            setMessage('Failed to export students. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchSubjectStudents()
    }, [])

    return (
        <div className='pt-2'>
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
                <p className='h3 fw-bold text-muted'>
                    {teachingClass.form.name} {teachingClass.subject.name} <LevelSplit primary={"Pupils"} ordinary={"Students"} />
                </p>
                <div className="d-flex flex-wrap">
                    <button
                        className="btn btn-sm btn-primary rounded-0 me-2"
                        disabled={isLoading}
                        onClick={() => exportStudents("excel")}
                    >
                        <span className="me-2">Export to Excel</span>
                        <i className="bi bi-file-earmark-spreadsheet"></i>
                    </button>
                    <button
                        className="btn btn-sm btn-primary rounded-0"
                        disabled={isLoading}
                        onClick={() => exportStudents("pdf")}
                    >
                        <span className="me-2">Export to PDF</span>
                        <i className="bi bi-file-earmark-pdf"></i>
                    </button>
                </div>
            </div>
            {
                message &&
                <div className={`alert alert-${variant} p-2`} role="alert">
                    {message}
                </div>
            }

            <div className="my-3">
                <DataTable
                    title={
                        <div className='d-flex align-items-center justify-content-between'>
                            <span>
                                {students.length} <LevelSplit primary={"Pupils"} ordinary={"Students"} />
                            </span>
                            {/* <button className='btn btn-primary rounded-0 fw-bold' onClick={handleEnrol}>
                                <span className="me-2">Enrol</span>
                                <i className='bi bi-person-fill-add'></i>
                            </button> */}
                        </div>
                    }
                    columns={students_columns}
                    data={students}
                    progressPending={isLoading}
                    progressComponent={
                        <div className="text-center">
                            Loading... <Loading />
                        </div>
                    }
                    noDataComponent={<>No <LevelSplit primary={"pupils"} ordinary={"students"} /> are enrolled in this subject.</>}
                />
            </div>
        </div>
    )
}

export default TeachingClassStudents
