import React, { useEffect, useState } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import SMSManageUsage from './SMSManageUsage'
import SMSManageOrders from './SMSManageOrders'
import SMSManagePrices from './SMSManagePrices'

function SMSManagement() {
	const [selectedTab, setSelectedTab] = useState(0)

	const handleTabChange = (index) => {
		setSelectedTab(index)
		sessionStorage.setItem("smsTab", index)
	}

	useEffect(() => {
		const savedTab = sessionStorage.getItem("smsTab")
		if (savedTab !== null) {
			setSelectedTab(parseInt(savedTab, 10))
		}
	}, [])

	return (
		<div className='pt-2'>
			<p>
				<span className='h3 fw-bold text-muted'>SMS Management</span>
				<br />
				<span>Manage SMS prices, purchase orders and usage history</span>
			</p>

			<Tabs selectedIndex={selectedTab} onSelect={handleTabChange}>
				<TabList>
					<Tab>Orders</Tab>
					<Tab>Prices</Tab>
					<Tab>Usage</Tab>
				</TabList>

				<TabPanel>
					<SMSManageOrders />
				</TabPanel>
				<TabPanel>
					<SMSManagePrices />
				</TabPanel>
				<TabPanel>
					<SMSManageUsage />
				</TabPanel>
			</Tabs>
		</div>
	)
}

export default SMSManagement
