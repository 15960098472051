import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../context/AuthContext'
import Loading from '../../../components/loading/Loading'
import { BASE_API_URL } from '../../../utils/constants'
import DataTable from 'react-data-table-component'
import ConfirmationDialog from '../../../components/dialogs/ConfirmationDialog'
import LevelSplit from '../../../components/offices/LevelSplit'

function DistrictDesigntaion() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState("")
    const [variant, setVariant] = useState("success")
    const [showDialog, setShowDialog] = useState(false)

    const [staffs, setStaffs] = useState([])
    const [designations, setDesignations] = useState([])
    const [dsao, setDsao] = useState("")
    const [dsslo, setDsslo] = useState("")
    const [dsaeo, setDsaeo] = useState("")
    const [dssneo, setDssneo] = useState("")
    const [wards, setWards] = useState([])
    const [ward, setWard] = useState("")
    const [aek, setAek] = useState("")

    const [toDelete, setToDelete] = useState({})

    const columns = [
        {
            name: 'S/N',
            cell: (row, index) => index + 1,
            sortable: false,
            grow: 0,
        },
        {
            name: 'Station',
            selector: row => row.station,
            sortable: true,
        },
        {
            name: 'Designation',
            selector: row => row.designation,
            sortable: true,
        },
        {
            name: 'Name of Staff',
            selector: row => `${row.user.firstname} ${row.user.lastname}`,
            sortable: true,
        },
        {
            name: 'Date of Designation',
            selector: row => row.designation_date,
            sortable: true,
        },
        {
            name: 'Action',
            cell: row => (
                <button
                    className='btn btn-sm btn-danger rounded-0'
                    onClick={() => {
                        setToDelete(row)
                        setShowDialog(true)
                    }}
                >
                    <span className="me-2">Remove</span>
                    <i className='bi bi-trash'></i>
                </button>
            ),
        }
    ]

    const fetchStaffs = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/employees/unallocated`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setStaffs(data.employees)
            }
        } catch (error) {
            setMessage('Failed to fetch staffs. Please try again.')
            setVariant('danger')
        } finally {
            setIsLoading(false)
        }
    }

    const fetchWards = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/wards/anything`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setWards(data.wards)
            }
        } catch (error) {
            setMessage('Failed to fetch staff wards. Please try again.')
            setVariant('danger')
        } finally {
            setIsLoading(false)
        }
    }

    const fetchDesignations = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/districts/designations`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setDesignations(data.designations)
            }
        } catch (error) {
            setMessage('Failed to fetch staff designations. Please try again.')
            setVariant('danger')
        } finally {
            setIsLoading(false)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/districts/designations`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({
                    dsao,
                    dsslo,
                    dsaeo,
                    dssneo,
                    "aek": {
                        "ward": ward,
                        "user": aek
                    }
                })
            })

            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant("warning")
            }
            else {
                setMessage(data.message)
                setVariant("success")
                fetchDesignations()
            }
        }
        catch (error) {
            setMessage("Failed to designate staffs. Please try again.")
            setVariant("danger")
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleDelete = async (userId, designation) => {
        setIsLoading(true)
        setMessage('')
        setShowDialog(false)

        try {
            const response = await fetch(`${BASE_API_URL}/districts/designations`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({
                    user: userId,
                    role: designation
                })
            })

            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setMessage(data.message)
                setVariant('success')
                fetchDesignations()
            }
        } catch (error) {
            setMessage('Failed to delete designation. Please try again.')
            setVariant('danger')
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchStaffs()
        fetchWards()
        fetchDesignations()
    }, [])

    return (
        <div className='pt-2'>
            <div className="d-flex align-items-center justify-content-between mb-3">
                <div>
                    <p className='h3 fw-bold text-muted'>Staff Designation</p>
                </div>
            </div>

            <ConfirmationDialog
                open={showDialog}
                onClose={() => {
                    setShowDialog(false)
                    setIsLoading(false)
                }}
                onConfirm={() => {
                    handleDelete(toDelete.user.id, toDelete.actual_designation)
                }}
                isLoading={isLoading}
                title='Remove Designation'
                message={`Are you sure you want to remove the user from designation?`}
            />

            {
                message &&
                <div className={`alert alert-${variant} p-2`} role="alert">
                    {message}
                </div>
            }

            <div className="my-3 shadow p-2">
                {
                    isLoading ?
                        <div className="text-center">
                            Loading... <Loading />
                        </div>
                        :
                        <>
                            <div className='mx-0 mb-4'>
                                <p className='h4 mb-3 text-muted'>Current Designations</p>
                                <DataTable
                                    columns={columns}
                                    data={designations}
                                    responsive
                                    highlightOnHover
                                    noDataComponent={"No designations found."}
                                />
                            </div>

                            <div className='mx-0 mb-4'>
                                <p className='h4 mb-3 text-muted'>Designate Staff</p>
                                <form onSubmit={handleSubmit}>
                                    <div className="row mb-3">
                                        <div className="col-lg mb-3">
                                            <div className="form-group">
                                                <label htmlFor="dao" className="form-label">
                                                    <LevelSplit
                                                        primary={"District Pre and Primary Academic Officer (DPPAO)"}
                                                        ordinary={"District Secondary Academic Officer (DSAO)"}
                                                    />
                                                </label>
                                                <select
                                                    id="dao"
                                                    className="form-select rounded-0"
                                                    disabled={isLoading}
                                                    value={dsao}
                                                    onChange={e => setDsao(e.target.value)}
                                                >
                                                    <option value="">Select Staff</option>
                                                    {
                                                        staffs.map(staff => (
                                                            <option key={staff.id} value={staff.id}>
                                                                {`${staff.firstname} ${staff.lastname}`}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-lg mb-3">
                                            <div className="form-group">
                                                <label htmlFor="slo" className="form-label">
                                                    <LevelSplit
                                                        primary={"District Pre and Primary Statistics and Logistics Officer (DPPSLO)"}
                                                        ordinary={"District Secondary Statistics and Logistics Officer (DSSLO)"}
                                                    />
                                                </label>
                                                <select
                                                    id="slo"
                                                    className="form-select rounded-0"
                                                    disabled={isLoading}
                                                    value={dsslo}
                                                    onChange={e => setDsslo(e.target.value)}
                                                >
                                                    <option value="">Select Staff</option>
                                                    {
                                                        staffs.map(staff => (
                                                            <option key={staff.id} value={staff.id}>
                                                                {`${staff.firstname} ${staff.lastname}`}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <LevelSplit
                                        primary={
                                            <>
                                                <div className="row mb-3">
                                                    <div className="col-lg mb-3">
                                                        <div className="form-group">
                                                            <label htmlFor="aeo" className="form-label">
                                                                <LevelSplit
                                                                    primary={"District Pre and Primary Adult Education Officer (DPPAEO)"}
                                                                    ordinary={"District Secondary Adult Education Officer (DSAEO)"}
                                                                />
                                                            </label>
                                                            <select
                                                                id="aeo"
                                                                className="form-select rounded-0"
                                                                disabled={isLoading}
                                                                value={dsaeo}
                                                                onChange={e => setDsaeo(e.target.value)}
                                                            >
                                                                <option value="">Select Staff</option>
                                                                {
                                                                    staffs.map(staff => (
                                                                        <option key={staff.id} value={staff.id}>
                                                                            {`${staff.firstname} ${staff.lastname}`}
                                                                        </option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-lg mb-3">
                                                        <div className="form-group">
                                                            <label htmlFor="sneo" className="form-label">
                                                                <LevelSplit
                                                                    primary={"District Pre and Primary Special Needs Officer (DPPSNEO)"}
                                                                    ordinary={"District Secondary Special Needs Officer (DSSNEO)"}
                                                                />
                                                            </label>
                                                            <select
                                                                id="sneo"
                                                                className="form-select rounded-0"
                                                                disabled={isLoading}
                                                                value={dssneo}
                                                                onChange={e => setDssneo(e.target.value)}
                                                            >
                                                                <option value="">Select Staff</option>
                                                                {
                                                                    staffs.map(staff => (
                                                                        <option key={staff.id} value={staff.id}>
                                                                            {`${staff.firstname} ${staff.lastname}`}
                                                                        </option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        ordinary={null}
                                    />

                                    <div>
                                        <p className='h4 mb-3 text-muted'>Designate AEK</p>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-lg-6 mb-3">
                                            <div className="form-group">
                                                <label htmlFor="ward" className="form-label">Ward</label>
                                                <select
                                                    id="ward"
                                                    className="form-select rounded-0"
                                                    disabled={isLoading}
                                                    value={ward}
                                                    onChange={e => setWard(e.target.value)}
                                                >
                                                    <option value="">Select Ward</option>
                                                    {
                                                        wards.map(wd => (
                                                            <option key={wd.id} value={wd.id}>
                                                                {wd.name}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 mb-3">
                                            <div className="form-group">
                                                <label htmlFor="aek" className="form-label">Staff</label>
                                                <select
                                                    id="aek"
                                                    className="form-select rounded-0"
                                                    disabled={isLoading}
                                                    value={aek}
                                                    onChange={e => setAek(e.target.value)}
                                                >
                                                    <option value="">Select Staff</option>
                                                    {
                                                        staffs.map(staff => (
                                                            <option key={staff.id} value={staff.id}>
                                                                {`${staff.firstname} ${staff.lastname}`}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        message &&
                                        <div className={`alert alert-${variant} p-2`} role="alert">
                                            {message}
                                        </div>
                                    }

                                    <div className="text-end mb-3">
                                        <button type='submit' className='btn btn-primary px-4 rounded-0' disabled={isLoading}>
                                            {
                                                isLoading ?
                                                    <>Designating... <Loading /></> :
                                                    <>
                                                        <span className="me-2">
                                                            Designate
                                                        </span>
                                                        <i className='bi bi-floppy2-fill'></i>
                                                    </>
                                            }
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </>
                }
            </div>
        </div>
    )
}

export default DistrictDesigntaion
