import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import Loading from '../loading/Loading'
import { BASE_API_URL } from '../../utils/constants'
import { Link } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { saveAs } from 'file-saver'

function FacilitiesTable({ school, disableAction }) {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState("")
    const [variant, setVariant] = useState("success")

    const [facilities, setFacilities] = useState([{}])

    const columns = [
        {
            name: "S/N",
            selector: (row, index) => index + 1,
            grow: 0
        },
        {
            name: "Facility",
            selector: row => row.name,
            sortable: true,
            grow: 2
        },
        {
            name: "Required",
            selector: row => row.required,
            sortable: true
        },
        {
            name: "Present",
            selector: row => row.available,
            sortable: true
        },
        {
            name: "Deficit",
            selector: row => row.deficit,
            sortable: true
        },
        {
            name: "Excess",
            selector: row => row.excess,
            sortable: true
        },
        {
            name: "Action",
            cell: row => <div>
                <Link
                    to={`${row.id}`}
                    className={`btn btn-sm btn-primary rounded-0 ${disableAction ? 'disabled' : ''}`}
                >
                    <i className='bi bi-eye-fill fs-6 mx-1'></i>
                </Link>
            </div>,
            sortable: false
        }
    ]

    // fetches and exports facilities
    const fetchFacilities = async (type) => {
        setIsLoading(true)

        try {
            let url = `${BASE_API_URL}/facilities/scope`
            if (school) {
                url += `?school=${school}`
            }

            if (school && type) {
                url += `&export=${type}`
            }
            else if (type) {
                url += `?export=${type}`
            }

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                }
            })

            if (type && response.ok) {
                let suff = "MSSIS"
                if (auth.user.school) {
                    suff = auth.user.school.name
                }

                const filename = `${suff} School Facilities ${new Date().toISOString().split('T')[0]}.pdf`
                const blob = await response.blob()
                saveAs(blob, filename)
                return
            }

            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setFacilities(data.facilities)
            }
        }
        catch (error) {
            setMessage("Failed to fetch facilities details")
            setVariant("danger")
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchFacilities(null)
    }, [school])

    return (
        <div>
            {
                message &&
                <div className={`alert alert-${variant} p-2`} role="alert">
                    {message}
                </div>
            }

            <DataTable
                title={<div className="text-end">
                    <button 
                        className="btn btn-sm btn-primary rounded-0 fw-bold" 
                        disabled={isLoading}
                        onClick={e => fetchFacilities('PDF')}
                    >
                        <span className="me-2">Export to PDF</span>
                        <i className="bi bi-file-earmark-pdf"></i>
                    </button>
                </div>}
                columns={columns}
                data={facilities}
                progressPending={isLoading}
                progressComponent={<>Loading... <Loading /></>}
            />
        </div>
    )
}

export default FacilitiesTable