import React, { useState } from 'react'
import LevelSplit from '../../components/offices/LevelSplit'
import { useAuth } from '../../context/AuthContext'
import ConfirmationDialog from '../../components/dialogs/ConfirmationDialog'
import Loading from '../../components/loading/Loading'
import { BASE_API_URL } from '../../utils/constants'

function ClassUpgrade() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState("")
    const [variant, setVariant] = useState("success")

    const [showDialog, setShowDialog] = useState(false)

    const upgradeClass = async () => {
        setIsLoading(true)
        setMessage("")

        try {
            const response = await fetch(`${BASE_API_URL}/classes/upgrade`, {
                method: 'GET',
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant("warning")
            }
            else {
                setMessage(data.message)
                setVariant("success")
            }
        } catch (error) {
            setMessage("Something went wrong. Please try again.")
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted'>Class Upgrade</p>
            <p>
                Automatically enroll <LevelSplit primary={"pupils "} ordinary={"students "} />
                into new classes. E.g if <LevelSplit primary={"pupils "} ordinary={"students "} />
                were in <LevelSplit primary={"Class I"} ordinary={"Form I"} />,
                they will be enrolled to <LevelSplit primary={"Class II"} ordinary={"Form II"} />
            </p>

            {
                message &&
                <div className={`alert alert-${variant} p-2`} role="alert">
                    {message}
                </div>
            }

            <p>
                After this process is done:
                <ol>
                    <li>
                        You will not be able to edit teaching progress from the previous year.
                        Teaching progress will be reset to reflect a new year of studies.
                        Any further recording of teaching progress will be included in the current academic year.
                    </li>
                    <li>
                        You will not be able to edit scores of exams done in the previous years.
                    </li>
                    <li>
                        <LevelSplit primary={"Pupils "} ordinary={"Students "} />
                        will be enrolled to new subjects that are taught in their new classes.
                        <LevelSplit
                            primary={null}
                            ordinary={" Enrollment to optional subjects is done manually."}
                        />
                    </li>
                    <li>
                        <LevelSplit primary={"Pupils "} ordinary={"Students "} />
                        that are in their final classes i.e
                        <LevelSplit primary={" class VII "} ordinary={" Form IV "} />
                        will become <i>graduates</i>.
                    </li>
                    <li>
                        Class teachers and subject teachers from the previous year will be removed. You will need to
                        assign new class teachers and subject teachers after this is done.
                    </li>
                    <li>
                        No data from previous years will be lost. You will still be able to access and view
                        all results, download reports and complete all operations that don't modify the data.
                    </li>
                </ol>

                <span className="text-warning fw-bold">
                    This process is irreverisible and will alter many things in the system.
                    Please use it when you are sure of it.
                </span>
            </p>

            <ConfirmationDialog
                open={showDialog}
                onClose={() => {
                    setShowDialog(false)
                    setIsLoading(false)
                }}
                onConfirm={() => {
                    upgradeClass()
                    setShowDialog(false)
                }}
                isLoading={isLoading}
                title="Upgrade Class"
                message={
                    <>
                        Are you sure you want to upgrade
                        <LevelSplit primary={" pupils' "} ordinary={" students' "} />
                        classes in your school?
                    </>
                }
            />

            <div className="mb-3">
                <button 
                    className='btn btn-danger fw-bold rounded-0 w-100' 
                    disabled={isLoading}
                    onClick={() => { setShowDialog(true) }}
                >
                    <span className="me-2">Upgrade Class</span>
                    {
                        isLoading ? <Loading /> : <i className="bi bi-sort-numeric-up-alt fs-5"></i>

                    }
                </button>
            </div>
        </div>
    )
}

export default ClassUpgrade