import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../context/AuthContext'
import { useLocation, useParams } from 'react-router-dom'
import Loading from '../../../components/loading/Loading'
import { BASE_API_URL } from '../../../utils/constants'
import ConfirmationDialog from '../../../components/dialogs/ConfirmationDialog'

function UpdateTopicCoverage() {
    const auth = useAuth()
    const params = useParams()
    const subtopicId = params.subtopicId
    const location = useLocation()
    const form = location?.state.form
    const subject = location?.state.subject
    const topic = location?.state.topic
    const school = location?.state.school

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState("")
    const [variant, setVariant] = useState("success")

    const [toDelete, setToDelete] = useState("")
    const [showDialog, setShowDialog] = useState(false)

    const [subTopic, setSubTopic] = useState({})
    const [dateTaught, setDateTaught] = useState("")
    const [concept, setConcept] = useState("")
    const [dateCompleted, setDateCompleted] = useState("")
    const [periods, setPeriods] = useState("")

    const fetchSubTopic = async () => {
        setIsLoading(true)

        try {
            let url = `${BASE_API_URL}/subject-topic/subtopic/${subtopicId}`
            if (["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO"].includes(auth.user.role)) {
                url += `?school=${school.id}`
            }

            const response = await fetch(url, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setSubTopic(data.sub_topic)
            }
        }
        catch (error) {
            setMessage('Failed to fetch sub topic details. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const recordConcept = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setMessage("")

        try {
            const response = await fetch(`${BASE_API_URL}/subject-topic/subtopic/${subtopicId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({
                    concept,
                    dateTaught
                })
            })

            const data = await response.json()
            if (!response.ok) {
                setVariant("warning")
            }
            else {
                setVariant("success")
                fetchSubTopic()
            }
            setMessage(data.message)
        }
        catch (error) {
            setMessage("Failed to save concept covered. Pleas try again.")
            setVariant("danger")
        }
        finally {
            setIsLoading(false)
        }
    }

    const recordCompleted = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setMessage("")

        try {
            const response = await fetch(`${BASE_API_URL}/subject-topic/subtopic/${subtopicId}/completed`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({
                    dateCompleted,
                    periods
                })
            })

            const data = await response.json()
            if (!response.ok) {
                setVariant("warning")
            }
            else {
                setVariant("success")
                fetchSubTopic()
            }
            setMessage(data.message)
        }
        catch (error) {
            setMessage("Failed to save coverage. Pleas try again.")
            setVariant("danger")
        }
        finally {
            setIsLoading(false)
        }
    }

    const deleteConcept = async (conceptId) => {
        setIsLoading(true)
        setMessage("")
        setShowDialog(false)

        try {
            const response = await fetch(`${BASE_API_URL}/subject-topic/concept-covered/${conceptId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                setVariant("warning")
            }
            else {
                setVariant("success")
                fetchSubTopic()
            }
            setMessage(data.message)
        }
        catch (error) {
            setMessage("Failed to delete concept covered. Pleas try again.")
            setVariant("danger")
        }
        finally {
            setIsLoading(false)
            setToDelete("")
        }
    }

    useEffect(() => {
        fetchSubTopic()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted'>Subject Sub Topic</p>
            <p className='fw-bold px-1'>
                {form?.name} {subject?.name}
                <br />
                {topic?.name}
                <br />
                {subTopic?.name}
            </p>

            {
                message &&
                <div className={`alert alert-${variant} p-2`} role="alert">
                    {message}
                </div>
            }

            {
                isLoading ?
                    <div className="text-center">
                        Loading... <Loading />
                    </div>
                    :
                    <>
                        <div className="shadow p-2 mb-3">
                            <span className='fs-5 text-muted'>
                                Teaching Status: <strong>{subTopic?.teaching_progress?.status || "Not Taught"}</strong>
                                <br />
                                Date Started: <strong>{subTopic?.teaching_progress?.start_date || "-"}</strong>
                                <br />
                                Date Completed: <strong>{subTopic?.teaching_progress?.end_date || "-"}</strong>
                                <br />
                                Periods Spent to Cover Subtopic: <strong>{subTopic?.teaching_progress?.periods_taught || "0"}</strong>
                            </span>
                            <br />

                            <span className='fs-5 text-muted'>Concepts Covered</span>
                            {
                                subTopic?.concepts_covered?.length < 1 &&
                                <p className="text-center">No concepts covered.</p>
                            }
                            <ol>
                                {
                                    subTopic?.concepts_covered?.map((concept, index) => (
                                        <li>
                                            <span className="me-2">
                                                {concept.date_taught} - {concept.concept}
                                            </span>
                                            {
                                                !["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO"].includes(auth.user.role) &&
                                                <button
                                                    className='btn btn-sm p-1 rounded-0'
                                                    disabled={isLoading}
                                                    onClick={() => {
                                                        setToDelete(concept.id)
                                                        setShowDialog(true)
                                                    }}
                                                >
                                                    <i className="bi bi-trash text-danger"></i>
                                                </button>
                                            }
                                        </li>
                                    ))
                                }
                            </ol>
                        </div>

                        <ConfirmationDialog
                            open={showDialog}
                            onClose={() => {
                                setShowDialog(false)
                                setIsLoading(false)
                            }}
                            onConfirm={() => {
                                deleteConcept(toDelete)
                            }}
                            isLoading={isLoading}
                            title='Delete Concept Covered'
                            message={`Are you sure you want to delete this concept?`}
                        />

                        {
                            !["DSEO", "DSAO", "DSSLO", "DPPEO", "DPPAO", "DPPSLO", "DPPAEO", "DPPSNEO"].includes(auth.user.role) &&
                            <>
                                <div className="shadow p-2 mb-3">
                                    <p>
                                        <span className='fs-5 text-muted'>
                                            Record Concepts Covered
                                        </span>
                                        <br />
                                        <span>
                                            Record the concepts you have covered in this sub topic.
                                            <br />
                                            If the sub topic is not taught, recording the first concept covered will mark it as
                                            <i> In Progress</i>
                                        </span>
                                    </p>
                                    <form onSubmit={recordConcept}>
                                        <div className='row mb-3'>
                                            <div className='col-lg-12 mb-3'>
                                                <div className='form-group'>
                                                    <label htmlFor='concept' className='form-label'>
                                                        Concept Covered (Specific Objective) <strong className='text-danger ms-1'>*</strong>
                                                    </label>
                                                    <textarea
                                                        id="concept"
                                                        className='form-control rounded-0'
                                                        required
                                                        disabled={isLoading}
                                                        value={concept}
                                                        onChange={e => setConcept(e.target.value)}
                                                        rows={5}
                                                    ></textarea>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 mb-3'>
                                                <div className='form-group'>
                                                    <label htmlFor='date_taught' className='form-label'>
                                                        Date to cover each Concept <strong className='text-danger ms-1'>*</strong>
                                                    </label>
                                                    <input
                                                        type='date'
                                                        className='form-control rounded-0'
                                                        id='date_taught'
                                                        required
                                                        disabled={isLoading}
                                                        value={dateTaught}
                                                        onChange={e => setDateTaught(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-lg-4 mb-3'></div>
                                            <div className='col-lg-4 mb-3 text-end mt-auto'>
                                                <button
                                                    type='submit'
                                                    className='btn btn-primary px-3 rounded-0'
                                                    disabled={isLoading}
                                                >
                                                    <span className="me-2">
                                                        Save
                                                    </span>
                                                    <i className='bi bi-floppy2-fill'></i>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <div className="shadow p-2 mb-3">
                                    <p>
                                        <span className='fs-5 text-muted'>
                                            Record Teaching
                                        </span>
                                        <br />
                                        <span>
                                            Record that you have completed teaching this sub topic.
                                            <br />
                                            This will mark the sub topic as <i>Taught</i>
                                        </span>
                                    </p>
                                    <form onSubmit={recordCompleted}>
                                        <div className='row mb-3'>
                                            <div className='col-lg-4 mb-3'>
                                                <div className='form-group'>
                                                    <label htmlFor='date_completed' className='form-label'>
                                                        Date completed <strong className='text-danger ms-1'>*</strong>
                                                    </label>
                                                    <input
                                                        type='date'
                                                        className='form-control rounded-0'
                                                        id='date_completed'
                                                        required
                                                        disabled={isLoading}
                                                        value={dateCompleted}
                                                        onChange={e => setDateCompleted(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-lg-4 mb-3'>
                                                <div className='form-group'>
                                                    <label htmlFor='periods' className='form-label'>
                                                        Periods Spent to Cover Subtopic <strong className='text-danger ms-1'>*</strong>
                                                    </label>
                                                    <input
                                                        type='text'
                                                        className='form-control rounded-0'
                                                        id='periods'
                                                        placeholder='e.g 2'
                                                        required
                                                        disabled={isLoading}
                                                        value={periods}
                                                        onChange={e => setPeriods(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-lg-4 mb-3 text-end mt-auto'>
                                                <button
                                                    type='submit'
                                                    className='btn btn-primary px-3 rounded-0'
                                                    disabled={isLoading}
                                                >
                                                    <span className="me-2">
                                                        Save
                                                    </span>
                                                    <i className='bi bi-floppy2-fill'></i>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </>
                        }
                    </>
            }
        </div>
    )
}

export default UpdateTopicCoverage
